<template>
  <v-container class="height-100 login-bkg" fluid>
    <v-row :class="$vuetify.breakpoint.xs ? 'pt-75' : 'pt-150'">
      <v-spacer></v-spacer>
      <v-col cols="12" sm="10" md="7" lg="5" xl="4">
        <v-card tile>
          <v-system-bar
            height="60"
            color="primary"
            class="white--text elevation-5"
          >
            <v-card-title>
              <h3>Developer Login</h3>
            </v-card-title>
          </v-system-bar>
          <v-system-bar height="5" color="accent" class=""></v-system-bar>
          <v-card-text>
            <span class="text-body-1 text--primary">
              To continue, log in to Resumation
            </span>
          </v-card-text>
          <v-divider />
          <v-card-text>
            <v-form ref="form">
              <v-text-field
                v-model="username"
                label="UserName"
                :rules="rulesUsername"
              ></v-text-field>
              <v-text-field
                v-model="userID"
                label="User ID"
                :rules="rulesId"
              ></v-text-field>
              <v-text-field
                v-model="authType"
                :disabled="true"
                label="Authentication Type"
              ></v-text-field>
              <v-btn @click="devLogin()" outlined> Log In </v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
  </v-container>
</template>

<script>
import * as env from "../../utility/env";
import { mapMutations } from "vuex";
import * as log from "../../utility/logging/logger";

// @ is an alias to /src
export default {
  name: "Login",
  data: () => ({
    username: "",
    userID: "",
    authType: "Dev",
    rulesUsername: [
      value => !!value || "Required.",
      value => (value && value.length >= 2) || "Min 2 characters"
    ],
    rulesId: [
      value => !!value || "Required.",
      value => /^[0-9]+$/.test(value) || "Only digits allowed."
    ]
  }),
  mounted() {
    this.checkValid();
    this.logout();
  },
  methods: {
    ...mapMutations("auth", ["login", "logout"]),
    checkValid() {
      if (!env.IsDevelopmentEnv()) {
        this.$router.push("Login");
      }
    },
    devLogin() {
      if (this.$refs.form.validate()) {
        this.login({
          username: this.username,
          id: this.userID,
          authType: this.authType
        });
        log.setLogConsole(true);
        this.$router.push("/Resume");
      }
    }
  },
  computed: {}
};
</script>


<style scoped>
.pt-150 {
  padding-top: 150px;
}

.pt-75 {
  padding-top: 75px;
}
</style>
